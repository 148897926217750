@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap");

$animation-timing: 1000ms;
$animation-delay: 300ms;

* {
	font-family: "Montserrat", sans-serif;
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	box-sizing: border-box;
	overflow: auto;
}

body {
	background-color: var(--base-colour);
	overflow-x: hidden;
}

.loading {
	position: absolute;
	height: 100vh;
	width: 100vw;
	background-color: hsl(225, 100%, 99%);
	top: 0px;
	left: 0px;
	z-index: 999;
	transition: 100ms ease;

	&.hidden {
		opacity: 0;
		pointer-events: none;
	}
}

section {
	height: 100%;
	padding: 2rem;
	overflow: visible;
}

.description {
	height: auto;
}

h1 {
	font-size: 44px;

	.pink {
		color: #fd366c;
	}

	.purple {
		color: hsl(237, 98%, 70%);
	}
}

video {
	width: 100%;
}

.size-warning-container {
	display: none;

	&.visible {
		position: absolute;
		display: flex;
		flex-direction: column;
		// align-items: center;
		justify-content: center;
		padding: 2rem;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 9;
		font-weight: 600;
		background-color: var(--base-colour);

		h2 {
			font-size: 28px;
			padding-bottom: 2rem;
		}
	}
}

.description {
	h2 {
		padding-top: 2rem;
	}

	p {
		padding: 1rem 0rem;
	}
}

.start-container {
	height: 100%;
	background-color: var(--base-colour);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	transition: 250ms ease;
	opacity: 1;
	overflow: visible;

	&.hidden {
		opacity: 0;
	}

	.posters {
		position: relative;
		overflow: visible;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;

		.poster {
			aspect-ratio: 2/3;
			border-radius: 10px;
			box-shadow: 0px 10px 64px -3px rgba(0, 0, 0, 0.1);
			width: 20%;
		}

		.front-poster {
			transform: translate(0%, 0%);
			width: 45%;
			height: 100%;
			background-size: cover;
			z-index: 3;

			&.offscreen {
				transform: translate(150vw, 0%);
			}
		}

		.left-poster {
			position: absolute;
			transform: translateX(-60%) rotate(-10deg);
			filter: blur(2px);
			width: 40%;
			background-size: cover;
			z-index: 2;

			&.offscreen {
				transform: translate(-150vw, 0%) rotate(0deg);
			}
		}

		.right-poster {
			position: absolute;
			transform: translateX(70%) rotate(10deg);
			filter: blur(4px);
			width: 35%;
			background-size: cover;
			z-index: 2;

			&.offscreen {
				transform: translate(150vw, 0%) rotate(0deg);
			}
		}
	}

	.join-session-form {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;

		#session-name-input {
			background-color: var(--input-colour);
			border: 1px solid var(--input-border-colour);
			height: 3rem;
			width: 80vw;
			max-width: 500px;
			border-radius: 10px;
			font-size: 16px;
			font-weight: bold;
			text-align: center;

			&::placeholder {
				color: gray;
			}
		}
	}

	.buttons {
		display: flex;
		flex-direction: row-reverse;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		width: 100%;

		.btn {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 3rem;
			width: 70%;
			max-width: 300px;
			background-color: var(--primary-button);
			color: white;
			font-weight: 700;
			border-radius: 10px;

			&.new-session {
				text-decoration: none;
				background-color: var(--secondary-button);
				color: white;
			}
		}
	}
}

footer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: 2rem;
	text-align: center;
	font-size: 12px;
}
