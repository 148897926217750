@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap";
* {
  vertical-align: baseline;
  box-sizing: border-box;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-size: 100%;
  overflow: auto;
}

body {
  background-color: var(--base-colour);
  overflow-x: hidden;
}

.loading {
  height: 100vh;
  width: 100vw;
  z-index: 999;
  background-color: #fafbff;
  transition: all .1s;
  position: absolute;
  top: 0;
  left: 0;
}

.loading.hidden {
  opacity: 0;
  pointer-events: none;
}

section {
  height: 100%;
  padding: 2rem;
  overflow: visible;
}

.description {
  height: auto;
}

h1 {
  font-size: 44px;
}

h1 .pink {
  color: #fd366c;
}

h1 .purple {
  color: #686ffd;
}

video {
  width: 100%;
}

.size-warning-container {
  display: none;
}

.size-warning-container.visible {
  height: 100%;
  width: 100%;
  z-index: 9;
  background-color: var(--base-colour);
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  font-weight: 600;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.size-warning-container.visible h2 {
  padding-bottom: 2rem;
  font-size: 28px;
}

.description h2 {
  padding-top: 2rem;
}

.description p {
  padding: 1rem 0;
}

.start-container {
  height: 100%;
  background-color: var(--base-colour);
  opacity: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all .25s;
  display: flex;
  overflow: visible;
}

.start-container.hidden {
  opacity: 0;
}

.start-container .posters {
  width: 100%;
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: visible;
}

.start-container .posters .poster {
  aspect-ratio: 2 / 3;
  width: 20%;
  border-radius: 10px;
  box-shadow: 0 10px 64px -3px #0000001a;
}

.start-container .posters .front-poster {
  width: 45%;
  height: 100%;
  z-index: 3;
  background-size: cover;
  transform: translate(0%);
}

.start-container .posters .front-poster.offscreen {
  transform: translate(150vw);
}

.start-container .posters .left-poster {
  filter: blur(2px);
  width: 40%;
  z-index: 2;
  background-size: cover;
  position: absolute;
  transform: translateX(-60%)rotate(-10deg);
}

.start-container .posters .left-poster.offscreen {
  transform: translate(-150vw)rotate(0);
}

.start-container .posters .right-poster {
  filter: blur(4px);
  width: 35%;
  z-index: 2;
  background-size: cover;
  position: absolute;
  transform: translateX(70%)rotate(10deg);
}

.start-container .posters .right-poster.offscreen {
  transform: translate(150vw)rotate(0);
}

.start-container .join-session-form {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.start-container .join-session-form #session-name-input {
  background-color: var(--input-colour);
  border: 1px solid var(--input-border-colour);
  height: 3rem;
  width: 80vw;
  max-width: 500px;
  text-align: center;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
}

.start-container .join-session-form #session-name-input::placeholder {
  color: gray;
}

.start-container .buttons {
  width: 100%;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.start-container .buttons .btn {
  height: 3rem;
  width: 70%;
  max-width: 300px;
  background-color: var(--primary-button);
  color: #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  display: flex;
}

.start-container .buttons .btn.new-session {
  background-color: var(--secondary-button);
  color: #fff;
  text-decoration: none;
}

footer {
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  font-size: 12px;
  display: flex;
}

/*# sourceMappingURL=index.76f8abc8.css.map */
